<template>
  <div class="edit-address columns is-multiline">
    <!-- <div class="column is-6 mb-3">
      <div class="field">
        <input-text :placeholder="'Clinic name*'" v-model="lab_location.address.clinic_name" :name="'clinic_name'"></input-text>
      </div>
    </div> -->
    <div class="column is-6 mb-3">
      <div class="field">
        <input-text :placeholder="'Nombre*'" v-model="lab_location.address.name" :name="'location-name'"></input-text>
      </div>
    </div>
    <!-- <div class="column is-6 mb-3">
      <div class="field">
        <input-text :placeholder="'Apellidos*'" v-model="lab_location.address.last_name" :name="'last_name'"></input-text>
      </div>
    </div> -->

    <div class="column is-6 mb-3">
      <div class="field">
        <input-text :placeholder="'Email*'" v-model="lab_location.address.email" :name="'location-email'"></input-text>
      </div>
    </div>
    <div class="column is-6 mb-3">
      <div class="field">
        <input-text :placeholder="'Teléfono*'" v-model="lab_location.address.phone" :name="'location-phone'"></input-text>
      </div>
    </div>

    <div class="column is-6 mb-3">
      <div class="field">
        <input-text :placeholder="'Dirección*'" v-model="lab_location.address.address1" :name="'location-address1'"></input-text>
      </div>
    </div>
    <div class="column is-6 mb-3">
      <div class="field">
        <input-text :placeholder="'País*'" v-model="lab_location.address.country" :name="'location-country'" :disabled="true"></input-text>
      </div>
    </div>
    <div class="column is-6 mb-3">
      <div class="field">
        <div class="select is-primary is-full-width">
          <select v-model="lab_location.address.province">
            <option :value="''">Seleccione provincia*</option>
            <template v-for="(item, index) in provinces">
              <option :value="item" v-bind:key="index">{{item}}</option>
            </template>
          </select>
        </div>
      </div>
    </div>
    <!-- <div class="column is-6 mb-3">
      <div class="field">
        <input-text :placeholder="'Dirección 2*'" v-model="email" :name="'email'"></input-text>
      </div>
    </div>

    <div class="column is-6 mb-3">
      <div class="select is-primary is-full-width">
        <select>
          <option>País*</option>
          <option>With options</option>
        </select>
      </div>
    </div>
    <div class="column is-6 mb-3">
      <div class="select is-primary is-full-width">
        <select>
          <option>Provincia*</option>
          <option>With options</option>
        </select>
      </div>
    </div> -->

    <div class="column is-6 mb-3">
      <div class="field">
        <input-text :placeholder="'Ciudad*'" v-model="lab_location.address.city" :name="'location-city'"></input-text>
      </div>
    </div>
    <div class="column is-6 mb-3">
      <div class="field">
        <input-text :placeholder="'Código Postal*'" v-model="lab_location.address.zip" :name="'location-zip'"></input-text>
      </div>
    </div>

    <div class="column is-6 mb-3">
      <div class="field">
        <label class="checkbox">
          <input type="checkbox" v-model="lab_location.primary">
          Seleccionar como dirección de facturación
        </label>
      </div>
    </div>
    <div class="column is-6 mb-3" v-if="!isEditMode">
      <button class="button is-primary is-fullwidth is-medium" @click="createLocation()">Añadir dirección</button>
    </div>
    <div class="column is-6 mb-3" v-if="isEditMode">
      <button class="button is-primary is-fullwidth is-medium" @click="updateLocation(lab_location)">Actualizar dirección</button>
    </div>
    <!-- <div class="column is-6 mb-3" v-if="isEditMode">
      <button class="button is-primary is-fullwidth is-medium is-empty" @click="closeEdit()">Cerrar actualización</button>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputText from '@/components/FormElements/InputText.vue'
export default {
  name: 'EditAddress',
  components: {
    InputText
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false
    },
    locationToEdit: {
      type: Object,
      default: null
    },
    callback: {
      type: Function,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      laboratory: 'user/laboratory'
    })
  },
  data () {
    return {
      lab_location: {
        address: {
          name: '',
          email: '',
          phone: '',
          address1: '',
          zip: '',
          city: '',
          province: '',
          country: 'España'
        },
        primary: true
      },
      provinces: [
        'Álava',
        'Albacete',
        'Alicante',
        'Almería',
        'Asturias',
        'Ávila',
        'Badajoz',
        'Baleares',
        'Barcelona',
        'Burgos',
        'Cáceres',
        'Cádiz',
        'Cantabria',
        'Castellón',
        'Ciudad Real',
        'Córdoba',
        'La Coruña',
        'Cuenca',
        'Gerona',
        'Granada',
        'Guadalajara',
        'Guipúzcoa',
        'Huelva',
        'Huesca',
        'Jaén',
        'León',
        'Lérida',
        'Lugo',
        'Madrid',
        'Málaga',
        'Murcia',
        'Navarra',
        'Orense',
        'Palencia',
        'Las Palmas',
        'La Rioja',
        'Salamanca',
        'Segovia',
        'Sevilla',
        'Soria Soria',
        'Tarragona',
        'Santa Cruz de Tenerife',
        'Teruel',
        'Toledo',
        'Valencia',
        'Valladolid',
        'Vizcaya',
        'Zamora',
        'Zaragoza'
      ]
    }
  },
  watch: {
    'locationToEdit' (newVal) {
      if (newVal !== null) {
        this.lab_location = newVal
        this.lab_location.address.country = 'España'
      }
    }
  },
  methods: {
    updateLocation (location) {
      const self = this
      this.$store.dispatch('lab/updateLocation', {
        locationId: location.id,
        data: location,
        cb: (data) => {
          if (self.callback !== null) {
            self.callback()
          }
          console.log(data)
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    },
    createLocation () {
      const self = this
      this.$store.dispatch('lab/createLocation', {
        labId: this.laboratory.id,
        data: this.lab_location,
        cb: (data) => {
          if (self.callback !== null) {
            self.callback()
          }
          console.log(data)
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    }
  },
  created () {
    if (this.locationToEdit !== null) {
      this.lab_location = this.locationToEdit
      this.lab_location.address.country = 'España'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.edit-address{
}
@media screen and (max-width: 768px) {
  .edit-address{
  }
}
</style>
