<template>
  <div class="dashboard-lab-info">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-wrap">
          <div class="columns content-my-account-wrap">
            <lab-menu></lab-menu>
            <div class="column is-12 right-column">
              <h3 class="title is-4 pb-4 txt-c-mobile">Informaciones de facturación</h3>
              <div class="columns is-multiline">
                <div class="column is-12 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Razón social*'" v-model="laboratory.name" :name="'name'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Email*'" v-model="userInfo.email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Teléfono*'" v-model="laboratory.phone" :name="'phone'"></input-text>
                  </div>
                </div>

                <!-- <div class="column is-12 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Dirección*'" v-model="laboratory.address_line1" :name="'address_line1'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Provincia*'" v-model="laboratory.address_state" :name="'address_state'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Ciudad*'" v-model="laboratory.address_city" :name="'address_city'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Código Postal*'" v-model="laboratory.address_postal_code" :name="'address_postal_code'"></input-text>
                  </div>
                </div> -->

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'NIF*'" v-model="laboratory.tax_id" :name="'tax_id'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Nº licencia sanitaria*'" v-model="laboratory.license_number" :name="'license_number'"></input-text>
                  </div>
                </div>
              </div>

              <h4 class="title is-4 pb-3 txt-c-mobile">Nombre del responsable técnico</h4>
              <div class="columns is-multiline">
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Nombre'" v-model="userInfo.first_name" :name="'first_name'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Apellidos'" v-model="userInfo.last_name" :name="'last_name'"></input-text>
                  </div>
                </div>
                <div class="column is-6 is-offset-6 mb-3">
                  <button class="button is-primary is-fullwidth is-medium" @click="updateInfo()">Guardar</button>
                </div>
              </div>

              <hr>

              <div id="my-locations">
                <h3 class="title is-4">Mis direcciones</h3>
                <div class="columns is-variable is-3 is-flex-wrap-wrap">
                  <template v-for="location in locations">
                    <div v-bind:key="location.id" class="column is-6 mb-1">
                      <div class="has-background-light location-box">
                        <p class="pb-2"><strong class="txt-yellow is-uppercase" v-if="location.primary">Dirección de facturación</strong></p>
                        <p class="pb-4" v-if="laboratory.pickup_location !== null && pickUpLocationId === location.id"><strong class="txt-yellow is-uppercase">Dirección de recogida</strong></p>
                        <p>
                          <strong>{{location.address.clinic_name}}</strong><br>
                          <strong>{{location.address.name}}</strong><br>
                          {{location.address.address1}}<br>
                          {{location.address.zip}} {{location.address.city}} ({{location.address.province}}), {{location.address.country}}<br>
                          {{location.address.phone}}<br><br>
                          <a href="#" class="is-underlined mr-2" @click.prevent="editLocation(location)">Editar</a>
                          <a href="#" class="is-underlined mr-2" @click.prevent="deleteLocation(location)">Eliminar</a>
                          <a href="#" class="is-underlined mr-2" v-if="!location.primary" @click.prevent="setMainLocation(location)">Seleccionar como principal</a>
                          <a href="#" class="is-underlined" v-if="pickUpLocationId !== location.id" @click.prevent="updatePickUpAddress(location.id)">Seleccionar como dirección de recogida</a>
                        </p>
                      </div>
                    </div>
                  </template>
                  <div class="column is-6 mb-1">
                    <div class="has-background-light location-box is-empty">
                      <button @click="toggleAddLocation()"><span class="icon-filters-open" v-if="!isAddLocationOpen"></span><span class="icon-close" v-if="isAddLocationOpen"></span></button>
                    </div>
                  </div>
                </div>

                <h3 id="new-location" class="title is-4 pb-4 pt-4" v-if="isAddLocationOpen">Añadir una nueva dirección</h3>
                <h3 id="new-location" class="title is-4 pb-4 pt-4" v-if="isEditMode">Actualizar la dirección</h3>
                <edit-address v-if="isAddLocationOpen || isEditMode" :is-edit-mode="isEditMode" :location-to-edit="lab_address" :callback="closeEdit"></edit-address>
              </div>

              <hr>

              <h3 class="title is-4">Descripción</h3>
              <p class=" pb-4">
                Añade una breve descripción para que aparezca el la página de tu laboratorio
              </p>
              <div class="columns is-multiline">
                <div class="column is-12 mb-3">
                  <div class="field">
                    <text-area :placeholder="'Descripción principal'" v-model="laboratory.description" :name="'description_1'"></text-area>
                  </div>
                </div>
                <!-- <div class="column is-12 mb-3">
                  <div class="field">
                    <text-area :placeholder="'Descripción segundaria'" v-model="laboratory.description_2" :name="'description_2'"></text-area>
                  </div>
                </div> -->

                <div class="column is-6 is-offset-6 mb-3">
                  <button class="button is-primary is-fullwidth is-medium" @click="goTo('/checkout/oreder-completed')">Guardar</button>
                </div>
              </div>

              <hr>

              <h3 class="title is-4">Galería de imágenes</h3>
              <p class=" pb-4">
                Sube algunas imágenes para enseñar tus instalaciones o tus trabajos<br>
                <strong class="txt-yellow">DIMENSIONES ACONSEJADAS</strong>  720 x 480 pixels
              </p>
              <div class="columns mt-3 mb-4 is-mobile is-flex-wrap-wrap">
                <div class="column is-3 mb-3 has-text-centered is-half-mobile">
                  <div class="gallery-img-wrap">
                    <img src="../../assets/images/5-about-1-hero.jpg"/>
                  </div>
                  <p class="mt-3">
                    <a class="is-underlined" href="#">Eliminar</a>
                  </p>
                </div>
                <div class="column is-3 mb-3 has-text-centered is-half-mobile">
                  <div class="gallery-img-wrap empty">
                    <button class="add-gallery-img">
                      <span class="icon-filters-open"></span>
                    </button>
                  </div>
                </div>
                <div class="column is-3 mb-3 has-text-centered is-half-mobile">
                  <div class="gallery-img-wrap empty">
                    <button class="add-gallery-img">
                      <span class="icon-filters-open"></span>
                    </button>
                  </div>
                </div>
                <div class="column is-3 mb-3 has-text-centered is-half-mobile">
                  <div class="gallery-img-wrap empty">
                    <button class="add-gallery-img">
                      <span class="icon-filters-open"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-6 is-offset-6 mb-3">
                  <button class="button is-primary is-fullwidth is-medium" @click="goTo('/checkout/oreder-completed')">Guardar</button>
                </div>
              </div>

              <hr>

              <h3 class="title is-4 pb-4">Cambiar contraseña</h3>
              <div class="columns is-multiline">
                <div class="column is-6 mb-3">
                  <button class="button is-primary is-medium" @click="sendRecoverPassword()">Solicitar cambio de contraseña</button>
                </div>
              </div>

              <hr>

              <h3 class="title is-4 pb-4">Consentimientos</h3>
              <div class="columns is-multiline">
                <div class="column is-12 mb-3">
                  <div class="field">
                    <label class="checkbox">
                      <input type="checkbox">
                      Quiero recibir la newsletter de STLab
                    </label>
                  </div>
                </div>
                <div class="column is-12 mb-3">
                  <div class="field">
                    <label class="checkbox">
                      <input type="checkbox">
                      Doy mi consentimiento para el uso de mis datos con fines de marketing y para recibir ofertas y promociones.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import LabMenu from '@/components/Laboratory/LabMenu.vue'
import InputText from '@/components/FormElements/InputText.vue'
import TextArea from '@/components/FormElements/TextArea.vue'
import EditAddress from '@/components/Laboratory/EditAddress.vue'
import { ElemOffset, IsEmptyOrNull } from '@/utils/utils'

import { mapGetters } from 'vuex'
export default {
  name: 'LaboratoryLabInfo',
  components: {
    HeroImage,
    Footer,
    LabMenu,
    InputText,
    TextArea,
    EditAddress
  },
  data () {
    return {
      primaryLocationId: null,
      pickUpLocationId: null,
      lab_address: null,
      isEditMode: false,
      isAddLocationOpen: false,
      userInfo: {
        first_name: '',
        last_name: '',
        email: ''
      },
      labInfo: {
        name: '',
        phone: '',
        address_line1: '',
        address_state: '',
        address_city: '',
        address_postal_code: '',
        tax_id: '',
        license_number: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      laboratory: 'user/laboratory',
      locations: 'lab/locations'
    })
  },
  methods: {
    // LOCATION
    closeEdit () {
      this.isEditMode = false
      this.isAddLocationOpen = false
      this.lab_address = null
      // const self = this
      this.$nextTick(function () {
        const myAddressesElement = document.getElementById('my-locations')
        const myAddressesOffset = ElemOffset(myAddressesElement)
        window.scroll({
          top: myAddressesOffset.top,
          behavior: 'smooth'
        })
      })
    },
    editLocation (location) {
      this.isEditMode = true
      this.lab_address = location
      this.$nextTick(function () {
        const newAddressElement = document.getElementById('new-location')
        const elemOffset = ElemOffset(newAddressElement)
        window.scroll({
          top: elemOffset.top - 130,
          behavior: 'smooth'
        })
      })
    },
    deleteLocation (location) {
      const self = this
      if (location.primary) {
        this.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: 'No puedes eliminar la dirección de facturación'
          }
        })
      } else {
        this.$store.dispatch('lab/deleteLocation', {
          locationId: location.id,
          cb: (data) => {
            self.fetchLocations()
            console.log(data)
          },
          cbError: (error) => {
            self.errorMessage = '¡Error!'
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: error
              }
            })
          }
        })
      }
    },
    setMainLocation (location) {
      const self = this
      location.primary = true
      this.updateLocation(location, () => {
        self.fetchLocations()
      })
    },
    updateLocation (location, cb = null) {
      const self = this
      this.$store.dispatch('lab/updateLocation', {
        locationId: location.id,
        data: location,
        cb: (data) => {
          if (cb !== null) {
            cb()
          }
          console.log(data)
        },
        cbError: (error) => {
          self.errorMessage = '¡Error!'
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    },
    toggleAddLocation () {
      const self = this
      this.isEditMode = false
      this.isAddLocationOpen = !this.isAddLocationOpen
      this.lab_address = null
      this.$nextTick(function () {
        const newAddressElement = document.getElementById('new-location')
        const myAddressesElement = document.getElementById('my-locations')
        if (self.isAddLocationOpen) {
          const elemOffset = ElemOffset(newAddressElement)
          window.scroll({
            top: elemOffset.top - 130,
            behavior: 'smooth'
          })
        } else {
          const myAddressesOffset = ElemOffset(myAddressesElement)
          window.scroll({
            top: myAddressesOffset.top,
            behavior: 'smooth'
          })
        }
      })
    },
    updatePickUpAddress (locationId) {
      this.updateLab(locationId)
    },
    // END LOCATION
    updateUser () {
      const self = this
      this.$store.dispatch('user/updateUser', {
        params: this.userInfo,
        cb: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Update completed'
            }
          })
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    },
    updateLab (locationId = null) {
      const self = this

      const params = (locationId === null) ? this.labInfo : { pickup_location_id: locationId }

      this.$store.dispatch('lab/updateLab', {
        labId: this.laboratory.id,
        params: params,
        cb: (response) => {
          console.log(response)
          self.$store.commit({
            type: 'user/SET_LAB_INFO',
            lab: response
          })
          if (locationId !== null) {
            self.pickUpLocationId = locationId
          }
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    },
    updateInfo () {
      this.updateUser()
      this.updateLab()
    },
    sendRecoverPassword () {
      this.goTo('/laboratories/recover-password')
    },
    logout () {
      const self = this
      this.$store.dispatch('user/logOut', {
        cb: () => {
          self.goTo('/')
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on the log out'
            }
          })
        }
      })
    },
    goTo (path) {
      this.$router.push({ path: path })
    },
    setLocalData () {
      this.fetchLocations()
      this.userInfo = JSON.parse(JSON.stringify(this.user))
      this.labInfo = JSON.parse(JSON.stringify(this.laboratory))
    },
    fetchLocations () {
      const self = this
      this.$store.dispatch('lab/getLocations', {
        labId: this.laboratory.id,
        cb: (data) => {
          console.log(data)
          if (data.length === 1) {
            self.primaryLocationId = data[0].id
            if (IsEmptyOrNull(self.laboratory.pickup_location)) {
              // save the primary as the pickup address
              self.updatePickUpAddress(data[0].id)
            } else {
              self.pickUpLocationId = self.laboratory.pickup_location.id
            }
          } else {
            for (let i = 0; i < data.length; i++) {
              if (data[i].primary) {
                self.primaryLocationId = data[i].id
                if (IsEmptyOrNull(self.laboratory.pickup_location)) {
                  self.updatePickUpAddress(data[i].id)
                } else {
                  self.pickUpLocationId = self.laboratory.pickup_location.id
                }
              }
            }
          }
        },
        cbError: (error) => {
          self.errorMessage = '¡Error!'
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    }
  },
  created () {
    if (this.user.id === null || this.laboratory.id === null) {
      const self = this
      this.$store.dispatch('user/getUser', {
        cb: () => {
          self.setLocalData()
        },
        cbError: (error) => {
          console.log(error)
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    } else {
      this.setLocalData()
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard-lab-info{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -430px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-wrap{
        padding: 0px;
        flex-direction: column;
        .has-background-light{
          border-radius: 4px 0px 0px 4px;
        }
        .right-column{
          padding: 80px;
          padding-top: 40px;
          .location-box{
            min-height: 275px;
            border-radius: 8px;
            padding: 21px 28px;
            &.is-empty{
              position: relative;
              background-color: transparent;
              border: 3px solid #F6F4EF;
              button{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0px;
                margin: 0px;
                padding: 0px;
                background-color: transparent;
                cursor: pointer;
                &:hover{
                  span{
                    opacity: 1;
                  }
                }
                span{
                  opacity: .3;
                  font-size: 7rem;
                  transition: all .3s ease-out;
                }
              }
            }
          }
          .gallery-img-wrap{
            width: 100%;
            height: 90px;
            img{
              width: 100%;
              border-radius: 8px;
            }
            &.empty{
              button{
                width: 100%;
                height: 100%;
                background-color: transparent;
                border-radius: 8px;
                border: 3px solid #F6F4EF;
                span{
                  color: #F6F4EF;
                  font-size: 2.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .dashboard-lab-info{
    section{
      .my-account-wrap{
        .content-my-account-wrap{
          .right-column{
            .is-half-mobile{
              width: 50%!important;
            }
            .gallery-img-wrap{
              // height: auto;
              // width: 50%;
            }
          }
        }
      }
    }
  }
}
</style>
